import { Container, Flex, Box, BoxProps, Grid, Heading } from '@chakra-ui/react'
import Image from 'next/image'
import { FC, memo } from 'react'

import { s3ImageURL } from '@miimosa/common'

interface Props {
  slug: string
}

const DesktopVideo: FC<Props & BoxProps> = ({ slug }) => {
  const videoSrc = `${
    process.env.NEXT_PUBLIC_MIIMOSTREAM ?? 'https://miimostream.prod.miimosa.tech'
  }/innocent/videos-lagrandeclasseverte/${slug}`

  return (
    <Container variant="full" bgColor="#B2E2F5" position="relative" display={{ base: 'none', md: 'flex' }}>
      <Box width="100%" height="244px" position="absolute" top="-90px" left="0">
        <Image src={s3ImageURL('illus_haut.svg')} alt="" fill priority />
      </Box>
      <Container position="relative" variant="boxed" flexDirection="row" alignItems="center" py="80px">
        <Box display={{ base: 'none', md: 'block' }} zIndex="2" position="absolute" top="180px" left="10px">
          <Image alt="innocent" src={s3ImageURL('innocent-falling-leave.svg')} width="52" height="40" />
        </Box>
        <Box
          position="absolute"
          width="29px"
          display={{ base: 'none', md: 'block' }}
          top={{ md: '420px' }}
          height="29px"
          left={{ md: '-75px' }}
          transform="rotate(-50deg)"
        >
          <Image alt="innocent" src={s3ImageURL('red_butterfly.svg')} fill style={{ objectFit: 'cover' }} />
        </Box>
        <Box
          position="absolute"
          width="29px"
          display={{ base: 'none', md: 'block' }}
          top={{ md: '300px' }}
          height="29px"
          right={{ md: '-75px' }}
          transform="rotate(-150deg)"
        >
          <Image alt="innocent" src={s3ImageURL('red_butterfly.svg')} fill style={{ objectFit: 'cover' }} />
        </Box>
        <Grid width="100%" gridTemplateColumns="repeat(2, 1fr)" alignItems="center" justifyItems="center">
          <Flex direction="column" mt="-140px">
            <Box width="383px" height="137px" position="relative">
              <Image src={s3ImageURL('logo-inno-video.svg')} alt="la grande classe verte" fill />
            </Box>
            <Heading size="md" color="primary.TERTIARY">
              Scannez le QR code de votre bouteille avec votre téléphone, <br /> regardez la vidéo et innocent reverse
              1€
            </Heading>
          </Flex>
          <Flex width="375px" height="667px">
            <Box
              id="miiVid"
              as="video"
              width="100%"
              height="100%"
              objectFit="cover"
              disablePictureInPicture
              muted={false}
              autoPlay
              controlsList="noremoteplayback noplaybackrate nodownload nopictureinpicture"
              controls
              opacity={1}
              playsInline
            >
              <source src={videoSrc} type="video/mp4"></source>
            </Box>
          </Flex>
        </Grid>
      </Container>
    </Container>
  )
}

export default memo(DesktopVideo)
