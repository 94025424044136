import axios from 'axios'
import useSWR from 'swr'

import nextFetcher from '@miimosa/api/nextFetcher'
import type { VoteResult } from '@miimosa/common/types'
import { usePersistedState } from '@miimosa/design-system/lib/hooks'

const VOTE_VALUE = 0.2
// 5min
const VOTE_EXPIRE_MS = 5 * 60 * 1000

interface ProjectVoteReturn {
  canVote: boolean
  votedSlug?: string
  addVote: (slug: string) => Promise<void>
  getProjectAmount: (slug: string) => string
  totalAmount: number
}

interface VoteState {
  slug: string
  stamp: number
}

function useProjectVote(qrCodeActive: boolean, consumeQRCode: () => void): ProjectVoteReturn {
  const { data: votes, mutate } = useSWR<VoteResult>('/nextapi/vote', nextFetcher, {
    fallbackData: { total_vote_count: 0, projects: [] },
  })

  const [lastVote, setVote] = usePersistedState<VoteState | undefined>('innocent_last_vote', undefined)
  // Can vote is now is after last vote + VOTE_EXPIRE_MS or no vote
  const canVote = (lastVote ? lastVote.stamp < Date.now() - VOTE_EXPIRE_MS : true) && qrCodeActive
  // voted slug is undefined if user can vote
  const votedSlug = canVote ? undefined : lastVote?.slug

  // TODO: add faking
  const totalAmount = (votes?.total_vote_count ?? 0) * VOTE_VALUE

  return {
    canVote,
    votedSlug,
    addVote: async (slug: string) => {
      // const idx = votes.projects.findIndex((p) => p.slug === slug)
      // //TODO: fix numbers
      // if (idx === -1) {
      //   votes.projects.push({ slug, vote_count: 1, fake_vote_count: 0, total_vote_count: 1 })
      // } else {
      //   const voteCount = votes.projects[idx].vote_count
      //   votes.projects[idx].vote_count = voteCount + 1
      // }
      // votes.total_vote_count = votes.total_vote_count + 1
      setVote({ slug, stamp: new Date().getTime() })
      consumeQRCode()
      // setVotes(votes)

      await axios.post('/nextapi/vote', { slug })
      mutate()

      // return 0
    },
    getProjectAmount: (slug: string) => {
      if (!votes) return '0'

      for (const p of votes.projects) {
        if (p.slug === slug) {
          return (p.total_vote_count * VOTE_VALUE).toFixed(2)
        }
      }
      return '0'
    },
    totalAmount,
  }
}

export default useProjectVote
