import { useRouter } from 'next/router'
import { useEffect } from 'react'

import { usePersistedState } from '@miimosa/design-system'

const QR_CODE_INNOCENT = 'M11M054r0CK5'
const QR_CODE_MODE_DELAY = 15 * 60 * 1000

interface QRCode {
  stamp: number // time at which qr code was scanned
  active: boolean
}

export default function useQRCode() {
  const {
    push,
    query: { code },
  } = useRouter()
  const qrCodeInURL = code === QR_CODE_INNOCENT
  const [qrCode, setQRCode] = usePersistedState<QRCode>('innocent_qrcode', { stamp: 0, active: false })

  const displayQRCodeMode = Date.now() - qrCode.stamp < QR_CODE_MODE_DELAY
  const qrCodeActive = qrCode.active
  const consumeQRCode = () => setQRCode({ stamp: qrCode.stamp, active: false })

  useEffect(() => {
    if (qrCodeInURL) {
      // Reset stamp and set qr code active
      setQRCode({ stamp: Date.now(), active: true })
      push('/')
    }
  }, [qrCodeInURL, push, setQRCode])

  return { displayQRCodeMode, consumeQRCode, qrCodeActive }
}
