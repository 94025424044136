import axios from 'axios'
import qs from 'qs'

const client = axios.create({
  headers: {
    'Content-Type': 'application/json',
  },
  paramsSerializer: (params) => {
    return qs.stringify(params, { arrayFormat: 'repeat' })
  },
})

client.interceptors.response.use(
  function (response) {
    response.headers['set-cookie'] = undefined
    return response
  },
  function (error) {
    return Promise.reject(error)
  }
)

function nextFetcher<T>(key: string, params: Record<string, any>): Promise<T> {
  return client.get(key, { params }).then((k) => k.data)
}

export default nextFetcher
