import { Image, Text, Box, Flex } from '@chakra-ui/react'
import NextLink from 'next/link'
import { memo, useEffect, useState } from 'react'

import { Button, Icon, Close } from '@miimosa/design-system/components'

import { useVideoVote } from '@lib/hooks'

const VideoHeader = () => {
  const [showHead, setShowHead] = useState(true)

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowHead(false)
    }, 2000)
    return () => clearTimeout(timer)
  }, [])

  if (!showHead) return null

  return (
    <>
      <Flex className="fadeInTop" direction="column" width="100%" position="absolute" top="0" zIndex="1" left="0">
        <Image alt="" src={'images/illus_haut.svg'} width="100%" objectFit="cover" />
      </Flex>
      <Flex
        className="fadeInTop"
        direction="column"
        width="100%"
        align="center"
        position="absolute"
        top="45px"
        zIndex="1"
        left="0"
      >
        <Image alt="" src={'images/titre-video.svg'} width="186px" objectFit="cover" />
      </Flex>
    </>
  )
}

const MobileVideo = ({ authorizedSlugs, slug }: { slug: string; authorizedSlugs: string[] }) => {
  const { canVote, votedSlugs, addVote } = useVideoVote(authorizedSlugs)
  const [showEndButtons, setShowEndButtons] = useState(false)
  const [showBravo, setShowBravo] = useState(false)
  const [showVoted, setShowHasVoted] = useState(false)
  const otherVideoSlug = authorizedSlugs.filter((slugA) => slugA !== slug)[0]
  const hasVoted = votedSlugs.includes(slug)

  const videoSrc = `${
    process.env.NEXT_PUBLIC_MIIMOSTREAM ?? 'https://miimostream.prod.miimosa.tech'
  }/innocent/videos-lagrandeclasseverte/${slug}`

  useEffect(() => {
    if (hasVoted) {
      setShowHasVoted(true)
    }
  }, [hasVoted])

  useEffect(() => {
    if (canVote && slug && authorizedSlugs.includes(slug) && !votedSlugs.includes(slug)) {
      setShowBravo(true)
      addVote(slug)
    }
  }, [addVote, slug, votedSlugs, authorizedSlugs, canVote])

  useEffect(() => {
    const showEndButtons = () => {
      setShowEndButtons(true)
    }
    const videoEl = document.getElementById('miiVid') as HTMLVideoElement

    videoEl?.addEventListener('ended', showEndButtons, false)

    return () => {
      document.removeEventListener('ended', showEndButtons)
    }
  }, [])

  return (
    <Flex
      bgColor="black"
      position="relative"
      align="center"
      justify="center"
      width="100%"
      display={{ base: 'flex', md: 'none' }}
      height={{ base: '100vh', md: '100%' }}
    >
      <VideoHeader />
      <Flex
        position="absolute"
        top="15px"
        right="15px"
        zIndex="1"
        width="26px"
        height="26px"
        align="center"
        justify="center"
        background="white"
        borderRadius="13px"
      >
        <NextLink href={'/'} passHref>
          <Close color="primary.TERTIARY" width="auto" height="22px" />
        </NextLink>
      </Flex>
      {showVoted && !showBravo && (
        <Flex zIndex="1" className="exitTextVideo" opacity="0">
          <Text
            color="white"
            maxW="240px"
            textAlign="center"
            fontFamily="innocent-headline_bold"
            size="xl"
            textShadow="1px 1px 2px rgb(0 0 0 / 80%)"
          >
            Vous avez déjà reversé 1€ pour ce projet
          </Text>
        </Flex>
      )}
      {showBravo && (
        <Flex zIndex="1" className="exitTextVideo" opacity="0">
          <Icon name="YoupiLeft" color="#74AA50" />
          <Text
            color="white"
            maxW="240px"
            textAlign="center"
            fontFamily="innocent-headline_bold"
            size="xl"
            textShadow="1px 1px 2px rgb(0 0 0 / 80%)"
          >
            Bravo vous venez de reverser 1 euro au projet
          </Text>
          <Icon name="YoupiRight" color="#74AA50" />
        </Flex>
      )}
      {/* {!showEndButtons && canVote && votedSlugs.length < 3 && (
        <Text
          color="white"
          zIndex="1"
          className="exitTextVideo"
          maxW="240px"
          textAlign="center"
          fontFamily="innocent-headline_bold"
          size="3xl"
          opacity="0"
          textShadow="1px 1px 2px rgb(0 0 0 / 80%)"
        >
          Bravo vous venez de reverser 1 euro au projet
        </Text>
      )} */}

      {showEndButtons && (
        <Flex zIndex="1" direction="column" align="center" className="fadeInTop">
          <NextLink href={`/video?slug=${otherVideoSlug}`} passHref>
            <Button variant="primary">voir une autre vidéo</Button>
          </NextLink>
          <NextLink href={`/projects/${slug}`} passHref>
            <Button mt="4" variant="secondaryW">
              aller sur la page du projet
            </Button>
          </NextLink>
        </Flex>
      )}

      <Box
        id="miiVid"
        as="video"
        position="absolute"
        top="0"
        left="0"
        width="100%"
        height="100%"
        objectFit="cover"
        disablePictureInPicture
        muted={false}
        autoPlay
        controlsList="noremoteplayback noplaybackrate nodownload nopictureinpicture"
        controls
        opacity={showEndButtons ? 0.2 : 1}
        playsInline
      >
        <source src={videoSrc} type="video/mp4"></source>
      </Box>
    </Flex>
  )
}

export default memo(MobileVideo)
